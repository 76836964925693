import React  from 'react';

class MasajidList extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            masajidData: props.masajidData,
            filterKey: null
        }
    }

    componentDidMount() {
        //this.setState({masajidData: this.props.masajidData})
    }

    static getDerivedStateFromProps(props, state) {
        return {
            filterKey: props.filterKey,
            masajidData: props.masajidData
        };
    }

    render() {
        const {onFilterKeyChange} = this.props;

        const masajidData = this.state.masajidData;
        let isDataAvailable = this.state.masajidData.length > 0;
        let classess = isDataAvailable ? '' : ' empty';
        const filterOption = isDataAvailable > 0 && (
            <div className="filter-masjid">
                <input className="search" type="text" placeholder="Filter on masajid name)" onKeyUp={onFilterKeyChange}/>
            </div>);

        return (
            <div className={"list-container" + classess}>
                {filterOption}
                {
                    masajidData.map((masjid, index) =>
                        <MasajidListItem
                            key={index}
                            masjidData={masjid}
                            index={index}
                            filterKey={this.state.filterKey}
                            onHoverHandler={this.props.onHoverHandler}
                        />
                    )
                }
            </div>
        );
    }
}

export default MasajidList;

class MasajidListItem extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            data: []
        }
    }

    onMouseEnter = () => { this.sendData(1) };

    onMouseLeave = () => { this.sendData(0) };

    sendData = (show) => {
        this.props.onHoverHandler(this.props.index, show);
    };
    //
    // shouldComponentUpdate(nextProps, nextState, nextContext) {
    //     let isFocusChanged = nextProps.masjidData.focus !== this.props.masjidData.focus;
    //     let isFilterKeyChanged = nextProps.filterKey !== this.props.filterKey;
    //
    //     console.log('nextProps.masjidData', nextProps.masjidData.title, isFocusChanged , isFilterKeyChanged);
    //     return (isFocusChanged || isFilterKeyChanged);
    //
    // }

    render() {
        const filterKey = this.props.filterKey ? this.props.filterKey : '';
        const masjid = this.props.masjidData;
        const logo = masjid.masjid_logo ? masjid.masjid_logo : 'https://masjidiapp.com/images/Logo short.png';
        const title = masjid.title.toLowerCase();
        let classes = masjid.focus === 1 ? ' hover' : '';
        classes = classes + ((filterKey && filterKey !== '' && title.indexOf(filterKey.toLowerCase()) === -1) ? ' hide' : '');

        //console.log('MasajidListItem ...',filterKey, masjid.title.indexOf(filterKey));
        //console.log('filterKey', masjid.title, filterKey, (filterKey !== '' && masjid.title.indexOf(filterKey) === 0) );

        return ( <div className={"list-item" + classes} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
            <div className='title'>
                <div className='logo'><img src={logo} alt='Masjid Logo'/></div>
                <span className="label"><Highlight search={filterKey}>{masjid.title}</Highlight></span>
            </div>
            <div className='address'>{masjid.address}, {masjid.city}, {masjid.zip}, {masjid.state}, {masjid.country}.</div>
            <div className='phone-number'>{masjid.phone_number}</div> <a className='web-url' href={masjid.web_url}>{masjid.web_url}</a>
            <div className='masjid-id'>({masjid.masjid_id})</div>
        </div> );
    }

}

const escapeRegExp = (str = '') => (
    str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')
);

const Highlight = ({ search = '', children = '' }) => {
    const patt = new RegExp(`(${escapeRegExp(search)})`, 'i');
    const parts = String(children).split(patt);

    if (search) {
        return parts.map((part, index) => (
            patt.test(part) ? <mark key={index}>{part}</mark> : part
        ));
    } else {
        return children;
    }
};
