import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

class SimpleMap extends Component {
    static defaultProps = {
        center: {
            lng: -121.9946858,
            lat:37.5094079
        },
        zoom: 13
    };


    prepareMarkers = ()=> {
        const masajidData = this.props.masajidData;
        let mapCenter = { lng:  parseFloat(masajidData[0].longitude), lat:  parseFloat(masajidData[0].latitude) };
        let maxDistance = 0;

        let markers = masajidData.map((masjid, index) => {
            let dis = parseFloat( masjid.distance );
            maxDistance =  dis > maxDistance ? dis : maxDistance;

            return (<MasajidMarker
                key={index}
                index={index}
                lng={masjid.longitude}
                lat={masjid.latitude}
                masjidData={masjid}
                filterKey={this.props.filterKey}
                onHoverHandler={this.props.onHoverHandler}
            />)
        });

        let zoomLevel = Math.floor(Math.log2(97.27130 * Math.cos( masajidData[0].latitude * Math.PI / 500) * 300 / maxDistance));

        //console.log('maxDistance..', maxDistance, zoomLevel);
        return { markers: markers, zoomLevel: zoomLevel, mapCenter: mapCenter };
    };

    render() {
        //console.log('SimpleMap...',  this.props.masajidData.length);

        const masajidData = this.props.masajidData;

        let mapStyle = {
            height: '90vh',
            width: '100%',
            position:'relative',
            padding: '0',
            margin:'0'
        };
        const mapControlsSettings = {
            zoomControl: true,
            scrollwheel: false,
            mapTypeControl: true,
            scaleControl: true,
            streetViewControl: false,
            rotateControl: true,
            fullscreenControl: false
        };

        let isDataAvailable = masajidData.length > 0;
        let classess = isDataAvailable ? '' : ' full';
        let masjidMarkers = isDataAvailable ?
            this.prepareMarkers() :
            { markers: [], zoomLevel: this.props.zoom, mapCenter: this.props.center };

        //console.log(masjidMarkers.mapCenter);
        return (
            // Important! Always set the container height explicitly
            <div className={"map-container" + classess}>
                <div style={mapStyle}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: 'AIzaSyB5vg4eDs3PeSZA84ucI4rOP-bFs09w8x0' }}
                        defaultCenter={this.props.center }
                        center={masjidMarkers.mapCenter}
                        defaultZoom={this.props.zoom }
                        gestureHandling='none'
                        options={mapControlsSettings}
                        scrollwheel={false}
                        zoom={masjidMarkers.zoomLevel}>
                        {masjidMarkers.markers}
                    </GoogleMapReact>
                </div>
            </div>
        );
    }
}

export default SimpleMap;


class MasajidMarker extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            masjid: null
        }
    }

    componentDidMount= () => {
        this.setState({masjid : this.props.masjidData});
    };

    onMouseEnter = () => { this.sendData(1) };

    onMouseLeave = () => { this.sendData(0) };

    sendData = (show) => {
        this.props.onHoverHandler(this.props.index, show);
    };

    render() {
        const masjid = this.props.masjidData;
        const filterKey = this.props.filterKey ? this.props.filterKey : '';
        const title = masjid.title.toLowerCase();
        //console.log('MasajidMarker ...',filterKey);
        let supportedClass = masjid.supported === '1' ? 'support-masjid' : 'unsupport-masjid';
        supportedClass = supportedClass + ((filterKey && filterKey !== '' && title.indexOf(filterKey.toLowerCase()) === -1) ? ' hide' : '');
        supportedClass = supportedClass + (masjid.focus === 1 ? ' showMarker' : '');
        //const logo = masjid.masjid_logo ? masjid.masjid_logo : 'https://masjidiapp.com/images/Logo short.png';
        //this.setState({lng: masjid.longitude, lat: masjid.latitude});

        return ( <div className={"masjid-marker tooltip " + supportedClass} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
                <span className="tooltiptext">
                    <MasajidMarkerData masjidData={masjid}></MasajidMarkerData>
                </span>
        </div> );
    }

}

class MasajidMarkerData extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            masjid: null
        }
    }
    render() {
        let masjid = this.props.masjidData;
        return (
          <div className="masjid-data">
              <span className='name'><b>{masjid.title} ({masjid.masjid_id})</b></span>
              <table className="table table-my">
                  <tbody>
                  <tr>
                      <td className="info">Fajr</td>
                      <td className="ng-binding">{masjid.fajr_iqama_time}</td>
                      <td className="info">Zuhr</td>
                      <td className="ng-binding">{masjid.zuhr_iqama_time}</td>
                  </tr>
                  <tr>
                      <td className="info">Asr</td>
                      <td className="ng-binding">{masjid.asr_iqama_time}</td>
                      <td className="info">Maghrib</td>
                      <td className="ng-binding">{masjid.magrib_start_time}</td>
                  </tr>
                  <tr>
                      <td className="info">Isha&nbsp;</td>
                      <td className="ng-binding">{masjid.isha_iqama_time}</td>
                      <td className="info">Jumu'ah I&nbsp;</td>
                      <td className="ng-binding">{masjid.jumma1_iqama}</td>
                  </tr>
                  <tr>
                      <td className="info">Jumu'ah II</td>
                      <td className="ng-binding">{masjid.jumma2_iqama}</td>
                      <td className="info">&nbsp;</td>
                      <td> &nbsp;</td>
                  </tr>

                  </tbody>
              </table>
          </div>
        );
    }
}
