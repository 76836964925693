import React, { Component } from 'react';
import tw64Logo from '../assets/icon/tw-64.png';
import fb64Logo from '../assets/icon/fb-64.png';

class AppFooter extends Component {


    constructor(props){
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        return false;
    }

    render() {
        return (
            <div className="app-footer">
                <div className='web-links' align="center">
                    <a href='https://www.masjidiapp.com' target=''>Home</a>
                    <a href='https://www.masjidiapp.com' target=''>FAQ's</a>
                    <a href='https://www.masjidiapp.com' target=''>Download</a>
                    <a href='https://www.masjidiapp.com' target=''>Contact Us</a>
                    <a href='https://www.masjidiapp.com/admin' target=''>MasjidiPortal</a>
                </div>
                <div className="social" align="center">
                    <a href="https://twitter.com/appmasjidi" target="_blank1" alt="twitter-logo"><img src={tw64Logo} alt="twitter"/></a>
                    <a href="https://www.facebook.com/usmasjidi?ref=br_tf" target="_blank2"><img src={fb64Logo} alt="facebook"/></a>
                </div>
                <div align="center">Copyright © 2011-2025 UmmahSoft LLC.</div>
            </div>
        );
    }
}

export default AppFooter;
