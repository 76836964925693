import React  from 'react';
import SimpleMap from './SimpleMap.js';
import MasajidList from './MasjidList.js';
import './app-body.css';

class AppBody extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            masajidData: props.masajidData,
            filterKey: null
        }
    }

    componentDidMount() {
        //this.setState({masajidData: this.props.masajidData})
    }

    static getDerivedStateFromProps(props, state) {
        return { masajidData: props.masajidData };
    }

    onHoverHandler = (index_, show) => {
        //console.log('onHoverHandler...', index_, show);
        //console.log(this.state.masajidData[index_].title);
        this.setState({
            masajidData:  this.state.masajidData.map((masjid, index)=> {
                masjid.focus = 0;
                if(index === index_ && show === 1)
                    masjid.focus = 1;

                return masjid;
            })
        });
    };

    onFilterKeyChange = (event) => {
        const keyword =  event.target.value;
        //console.log('onFilterKeyChange Data...',  keyword);

        this.setState({
            filterKey: (keyword && keyword !== '') ? keyword : null,
        });

    };


    render() {
        //const masajidData = this.state.masajidData;
        //console.log('AppBody...',  masajidData);
        return (
            <div className="app-body">
                <MasajidList
                    masajidData={this.state.masajidData}
                    filterKey={this.state.filterKey}
                    onHoverHandler={this.onHoverHandler}
                    onFilterKeyChange={this.onFilterKeyChange}/>
                <SimpleMap
                    masajidData={this.state.masajidData}
                    filterKey={this.state.filterKey}
                    onHoverHandler={this.onHoverHandler}/>
            </div>
        );
    }
}

export default AppBody;
